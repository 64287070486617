import type { DepositListItem } from 'types/api/lithosphere/deposits';
import type { DatastoreListItem, DatastoreItem, DatastoreTxnListItem } from 'types/api/lithosphere/mantleDataStore';
import type { StateRootListItem } from 'types/api/lithosphere/stateRoot';
import type { WithdrawalListItem } from 'types/api/lithosphere/withdrawals';

export const DEPOSITS_LIST_ITEM: DepositListItem = {
  guid: '1',
  l1BlockNumber: 1801,
  queueIndex: null,
  l1TransactionHash: '0x846d998c40e871ef25387b86ba998fa0815c2fd9c66d3c074ef1861d83964d8d',
  l2TransactionHash: '0x4a786206ef2e64aad4df9f2541696826f2e0c41dad4910021a411b8d27b94b25',
  l1TxOrigin: '0x71df4a3bae150101d1b61c174e3ef537940b3f50a0855c2143389f32c96e8f3f',
  status: 1,
  fromAddress: '0x0000000000000000000000000000000000000000',
  toAddress: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
  l1TokenAddress: '0x0000000000000000000000000000000000000000',
  l2TokenAddress: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
  ETHAmount: 300000000000000000,
  ERC20Amount: 0,
  gasLimit: 300000,
  timestamp: 1698692723,
};

export const WITHDRAWALS_LIST_ITEM: WithdrawalListItem = {
  guid: '1',
  l2BlockNumber: 1800,
  msgNonce: 1.7668470647783843e+72,
  l2TransactionHash: '0x9d8a9be8cbdb5ef8aae1674d44d0847e708247ffdea2cef8ee5bc2e555d8ef8a',
  l2WithdrawTransactionHash: '0x34f861d8373824942acb56a9c6d2ab3419ac4b4fc4f15efcd3b9c96f818348e4',
  l1ProveTxHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
  l1FinalizeTxHash: '0x0000000000000000000000000000000000000000000000000000000000000000',
  status: 0,
  fromAddress: '0x4200000000000000000000000000000000000007',
  ETHAmount: 0,
  ERC20Amount: 100000000000000000,
  gasLimit: 592897,
  timeLeft: 0,
  toAddress: '0xb31bda60220f66aee4449a9b1d0839bc463fe1a5',
  l1TokenAddress: '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000',
  l2TokenAddress: '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000',
  timestamp: 1700710346,
};

export const DATASTORE_LIST_ITEM: DatastoreListItem = {
  dataStoreId: 100000,
  dataSize: 100000,
  status: true,
  timestamp: 190000,
  daHash: '0x00000000000000000000000000',
  externalRef: '',
};

export const DATASTORE_TXN_LIST_ITEM: DatastoreTxnListItem = {
  index: 100000,
  blockNumber: 1000000,
  txHash: '0x00000000000000000000000000',
  blockData: '0x00000000000000000000000000',
};

export const DATASTORE_ITEM: DatastoreItem = {
  dataStoreId: 234791,
  durationDataStoreId: 68,
  dataInitHash: '0x4abf6fc6a438a8c6170f6c3813cbbfec15231a885ab9e7ba88500ce8372c6821',
  dataConfirmHash: '0xbde5b8bd84c403df6e7b400c00bbed5fd9b1ff761b414546ce1a9744a40f3fdd',
  fromStoreNumber: 70,
  stakeFromBlockNumber: 49,
  initGasUsed: 9,
  initBlockNumber: 5,
  confirmGasUsed: 6,
  dataHash: '0xa43474c1192166ab3a0fbc4f7159b7aa6980a8b3adcc2f4d11da9806ee91897b',
  ethSign: '80000000000000000000',
  mantleSign: '0',
  signatoryRecord: 'ec8793e9bd751b8fd149a4cd182c25eb70b22c95b3436d1476411cf3b63d2536',
  initTime: 1700474952,
  expireTime: 1700478564,
  numSys: 2,
  numPar: 4,
  lowDegree: '4096',
  status: true,
  confirmer: '0x6c02edf129952246e4518604ae24007da1b54c3f97577f57898a4ce22c8d500d',
  header: '0xfd13aec3a1db0aed644ee36f1c29981cfef987c897be0cf2fca4cb0e5d488fce48ba3f8c9b8c92a79d254b55887636c0b2c6e039b9d398894b5f8d09f6cb171e1db6b762efd0bd',
  dataCommitment: '0xc0d58c2acd290e4047624741ee6fd7b15bba492b5e540c4bc0d0d83ec6b508b9',
  timestamp: 1699461659,
  dataSize: 5,
};

export const L1_STATE_ROOT_ITEM: StateRootListItem = {
  blockHash: '0x00000000000000000000000000',
  transactionHash: '0x00000000000000000000000000',
  l1BlockNumber: 1000000,
  l2BlockNumber: 1000000,
  outputIndex: 1000,
  prevTotalElements: null,
  status: 0,
  outputRoot: '0x00000000000000000000000000',
  canonical: false,
  batchSize: 1000,
  blockSize: 100,
  timestamp: 1000000,
};

import type { UseQueryResult } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { animateScroll } from 'react-scroll';

import type { PaginationRapParams } from './types';

import { RESOURCES, type PaginatedRapResources, type PaginationRapFilters, type ResourceError, type CustomResourcePayload } from 'lib/api/resources';
import type { Params as UseApiQueryParams } from 'lib/api/useCustomApiQuery';
import useApiQuery from 'lib/api/useCustomApiQuery';
import { LITHOSPHERE_PAGE_SIZE } from 'lib/consts';
import useDebounce from 'lib/hooks/useDebounce';
import getQueryParamString from 'lib/router/getQueryParamString';

export interface Params<Resource extends PaginatedRapResources> {
  resourceName: Resource;
  options?: UseApiQueryParams<Resource>['queryOptions'];
  pathParams?: UseApiQueryParams<Resource>['pathParams'];
  filters?: PaginationRapFilters<Resource>;
  scrollRef?: React.RefObject<HTMLDivElement>;
  pageSize?: number;
}

type NextPageParams = Record<string, unknown>;

function getPaginationParamsFromQuery(queryString: string | Array<string> | undefined) {
  if (queryString) {
    try {
      return JSON.parse(decodeURIComponent(getQueryParamString(queryString))) as NextPageParams;
    } catch (error) {}
  }

  return {};
}

export type QueryWithPagesRapResult<Resource extends PaginatedRapResources> =
UseQueryResult<CustomResourcePayload<Resource>, ResourceError<unknown>> &
{
  onFilterChange: (filters: PaginationRapFilters<Resource>) => void;
  pagination: PaginationRapParams;
}

export default function useQueryWithPagesRap<Resource extends PaginatedRapResources>({
  resourceName,
  filters,
  options,
  pathParams,
  scrollRef,
  pageSize = LITHOSPHERE_PAGE_SIZE,
}: Params<Resource>): QueryWithPagesRapResult<Resource> {
  const resource = RESOURCES[resourceName];
  const queryClient = useQueryClient();
  const router = useRouter();

  const [ page, setPage ] = React.useState<number>(router.query.page && !Array.isArray(router.query.page) ? Number(router.query.page) : 1);
  const [ pageParams, setPageParams ] = React.useState<Record<number, NextPageParams>>({
    [page]: getPaginationParamsFromQuery(router.query.next_page_params),
  });
  const [ hasPages, setHasPages ] = React.useState(page > 1);

  const [ pageInput, setPageInput ] = React.useState('');

  const debouncedPageInput = useDebounce(pageInput, 500);

  const isMounted = React.useRef(false);
  const canGoBackwards = React.useRef(!router.query.page);

  // type QueryParamsType = {
  //   page?: string;
  //   page_size?: string;
  // }
  const queryParams = { ...pageParams[page], ...filters };

  const onPageInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(event.target.value);
  }, []);

  const scrollToTop = useCallback(() => {
    scrollRef?.current ? scrollRef.current.scrollIntoView(true) : animateScroll.scrollToTop({ duration: 0 });
  }, [ scrollRef ]);

  const queryResult = useApiQuery(resourceName, {
    pathParams,
    queryParams: {
      ...queryParams,
      pageSize: pageSize,
      page: page,
    },
    queryOptions: {
      staleTime: page === 1 ? 0 : Infinity,
      ...options,
    },
  });
  const { data } = queryResult;

  const onNextPageClick = useCallback(() => {
    if (!data) {
      return;
    }
    const { Current, Total, Size } = data;

    if (Size * Current > Total) {
      return;
    }

    setPageParams((prev) => ({
      ...prev,
      [page + 1]: { page: Current + 1 },
    }));

    setPage(prev => prev + 1);

    const nextPageQuery = {
      ...router.query,
      page: String(page + 1),
      next_page_params: encodeURIComponent(JSON.stringify({ page: Current + 1 })),
    };

    setHasPages(true);
    scrollToTop();
    router.push({ pathname: router.pathname, query: nextPageQuery }, undefined, { shallow: true });

  }, [ data, page, router, scrollToTop ]);

  const onPrevPageClick = useCallback(() => {
    // returning to the first page
    // we dont have pagination params for the first page
    let nextPageQuery: typeof router.query = { ...router.query };

    if (page === 2) {
      nextPageQuery = omit(router.query, [ 'next_page_params', 'page' ]);
      canGoBackwards.current = true;
    } else {
      nextPageQuery.next_page_params = encodeURIComponent(JSON.stringify(pageParams[page - 1]));
      nextPageQuery.page = String(page - 1);
    }

    scrollToTop();
    router.push({ pathname: router.pathname, query: nextPageQuery }, undefined, { shallow: true })
      .then(() => {
        setPage(prev => prev - 1);
        page === 2 && queryClient.removeQueries({ queryKey: [ resourceName ] });
      });
  }, [ router, page, pageParams, scrollToTop, queryClient, resourceName ]);

  const resetPage = useCallback(() => {
    queryClient.removeQueries({ queryKey: [ resourceName ] });

    scrollToTop();
    const nextRouterQuery = omit(router.query, [ 'next_page_params', 'page' ]);
    router.push({ pathname: router.pathname, query: nextRouterQuery }, undefined, { shallow: true }).then(() => {
      queryClient.removeQueries({ queryKey: [ resourceName ] });
      setPage(1);
      setPageParams({});
      canGoBackwards.current = true;
      window.setTimeout(() => {
        // FIXME after router is updated we still have inactive queries for previously visited page (e.g third), where we came from
        // so have to remove it but with some delay :)
        queryClient.removeQueries({ queryKey: [ resourceName ], type: 'inactive' });
      }, 100);
    });
  }, [ queryClient, resourceName, router, scrollToTop ]);

  const onFilterChange = useCallback((newFilters: PaginationRapFilters<Resource> | undefined) => {
    // in progress
    const newQuery = omit<typeof router.query>(router.query, 'next_page_params', 'page', resource.filterFields);
    if (newFilters) {
      Object.entries(newFilters).forEach(([ key, value ]) => {
        if (value && value.length) {
          newQuery[key] = Array.isArray(value) ? value.join(',') : (value || '');
        }
      });
    }
    scrollToTop();
    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    ).then(() => {
      setHasPages(false);
      setPage(1);
      setPageParams({});
    });
  }, [ router, resource.filterFields, scrollToTop ]);

  let hasNextPage = false;
  if (data) {
    const { Current, Total, Size } = data;
    if (Size * Current < Total) {
      hasNextPage = true;
    }
  }

  const onTargetPageClick = useCallback((target: number) => {
    if (!data) {
      return;
    }
    if (target === data.Current) {
      return;
    }
    setPageParams((prev) => ({
      ...prev,
      [target]: { page: target },
    }));

    setPage(target);

    const targetPageQuery = {
      ...router.query,
      page: String(target),
      next_page_params: encodeURIComponent(JSON.stringify({ page: target })),
    };

    setHasPages(true);
    scrollToTop();
    router.push({ pathname: router.pathname, query: targetPageQuery }, undefined, { shallow: true });
  }, [ data, router, scrollToTop ]);

  const totalCount = data?.Total || 0;
  const totalPageCount = Math.ceil(totalCount / pageSize);

  React.useEffect(() => {
    const inputVal = Number(debouncedPageInput);
    if (isNaN(inputVal) || inputVal > totalPageCount || inputVal < 1) {
      return;
    }
    onTargetPageClick(inputVal);
    // hook should run only when debouncedPageInput has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ debouncedPageInput ]);

  const pagination = {
    page,
    onNextPageClick,
    onPrevPageClick,
    resetPage,
    onTargetPageClick,
    onPageInputChange,
    pageInput,
    totalCount,
    pageSize,
    hasPages,
    hasNextPage,
    canGoBackwards: canGoBackwards.current,
    isLoading: queryResult.isPlaceholderData,
    isVisible: totalPageCount > 1,
  };

  React.useEffect(() => {
    if (page !== 1 && isMounted.current) {
      queryClient.cancelQueries({ queryKey: [ resourceName ] });
      setPage(1);
    }
  // hook should run only when queryName has changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ resourceName ]);

  React.useEffect(() => {
    window.setTimeout(() => {
      isMounted.current = true;
    }, 0);
  }, []);

  return { ...queryResult, pagination, onFilterChange };
}

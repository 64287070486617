import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import useIsMobile from 'lib/hooks/useIsMobile';
import { DEPOSITS_LIST_ITEM } from 'stubs/lithosphere';
import { generateCustomListStub } from 'stubs/utils';
import LinkInternal from 'ui/shared/links/LinkInternal';
import useQueryWithPagesRap from 'ui/shared/pagination/useQueryWithPagesRap';

import LatestDepositsItem from './LatestDepositsItem';

const LatestDeposits = () => {
  const isMobile = useIsMobile();
  const itemsCount = isMobile ? 2 : 6;

  const { data, isError, isPlaceholderData } = useQueryWithPagesRap({
    resourceName: 'mantle_deposits',
    filters: { address: '0x00' },
    pageSize: 6,
    options: {
      placeholderData: generateCustomListStub<'mantle_deposits'>(
        DEPOSITS_LIST_ITEM,
        6,
      ),
    },
  });

  if (isError) {
    return <Text mt={ 4 }>No data. Please reload page.</Text>;
  }

  if (data) {
    const depositsUrl = route({ pathname: '/deposits' });
    return (
      <>
        <Box mb={{ base: 3, lg: 4 }}>
          { data.Records.slice(0, itemsCount).map(((item, index) => (
            <LatestDepositsItem
              key={ item.guid + (isPlaceholderData ? index : '') }
              item={ item }
              isLoading={ isPlaceholderData }
            />
          ))) }
        </Box>
        <Flex justifyContent="center">
          <LinkInternal fontSize="sm" href={ depositsUrl }>View all deposits</LinkInternal>
        </Flex>
      </>
    );
  }

  return null;
};

export default LatestDeposits;
